export type AnalyticsServicePayload = {
  sessionId: string;
  checkoutSessionId?: string;
  fieldName: string;
  fieldKey: string;
  fieldValue: string;
  fieldType: string;
  source: string;
  clientTimestamp: string;
};

export type PartialAnalyticsPayload = Pick<
  AnalyticsServicePayload,
  'fieldName' | 'fieldValue' | 'fieldKey' | 'fieldType'
>;

type AnalyticsPayloadEventBody = {
  analytics: [AnalyticsServicePayload];
};

export type AnalyticsServicePayloadEvent = {
  eventType: string;
  body: AnalyticsPayloadEventBody;
};

export enum AnalyticsServiceFieldName {
  FIXTER_SESSION_ID = 'sessionId',
  GOOGLE_ANALYTICS = 'googleAnalytics',
  EXPERIMENTS = 'experiments',
  MARKETING = 'marketing',
  TRACK_BOOKING = 'trackBooking',
  EWO = 'ewo',
  PRODUCT = 'product',
  SCHEDULE_PAGE = 'schedulePage',
  PAYMENT_PAGE = 'paymentPage',
  CALLOUT_CARD_CONTACT_US = 'calloutCardContactUs',
  TEAM_SUPPORT_SECTION = 'teamSupportSection',
  NEED_HELP = 'needHelp',
  BOOKING_PAGE = 'bookingPage',
  QUOTES_PAGE = 'quotesPage',
  REAUTHORISE_PAYMENT = 'reauthorisePaymentPage',
}

export enum AnalyticsServiceFieldKey {
  FIXTER_SESSION_ID = 'fixterSessionId',
  GCLID = 'gclid',
  BOOKING_ID = 'bookingId',
  PAGE_VIEW = 'pageView',
  PAGE_LOAD_EXPERIMENTS = 'pageLoadExperiments',

  // Review EWO proposals interactions
  EWO_PROPOSED_PAGE_VIEW = 'ewoProposedPageView',
  EWO_ACTIVE_EXPERIMENTS_USED = 'ewoActiveExperimentsUsed',
  EWO_PROPOSED_APPROVE = 'ewoProposedApprove',
  EWO_PROPOSED_DECLINE = 'ewoProposedDecline',
  EWO_PROPOSED_VIEWED_ALL = 'ewoProposedViewedAll',
  EWO_PROPOSED_PAGE_CONFIRM_CLICKED = 'ewoProposedPageConfirmClicked',
  EWO_CONFIRM_PAYMENT_PAGE_VIEW = 'ewoConfirmPaymentPageView',
  EWO_CONFIRM_PAYMENT_METHOD_SELCTED = 'ewoConfirmPaymentMethodSelected',
  EWO_PAYMENT_CONFIRM_CLICKED = 'ewoPaymentConfirmClicked',
  EWO_CONFIRM_PAYMENT_STRIPE_FAILURE = 'ewoConfirmPaymentStripeFailure',
  EWO_PAYMENT_ALL_APPROVED = 'ewoPaymentAllApproved',
  EWO_PAYMENT_ALL_DECLINED = 'ewoPaymentAllDeclined',
  EWO_CONFIRMED_PAYMENT_DETAILS = 'ewoConfirmedPaymentDetails',
  EWO_SUCCESS_PAGE_VIEW = 'ewoSuccessPageView',

  // Reauthorise payment interactions
  REAUTHORISE_PAYMENT_PAGE_VIEW = 'reauthorisePaymentPageView',
  REAUTHORISE_PAYMENT_SUBMIT_CLICKED = 'reauthorisePaymentSubmitClicked',
  REAUTHORISE_PAYMENT_STRIPE_FAILED = 'reauthorisePaymentStripeFailed',
  REAUTHORISE_PAYMENT_CONFIRMED_PAYMENT_DETAILS = 'reauthorisePaymentConfirmedPaymentDetails',

  // Booking Tracking Page Interactions
  TRACK_BOOKING_COMPONENT_DISPLAYED = 'trackBookingComponentDisplayed',
  TRACK_MY_BOOKING_CLICKED = 'trackMyBookingClicked',
  TRACK_HELP_CARD_CONTACT_US_CLICKED = 'trackHelpCardContactUsClicked',
  TRACK_HELP_CARD_FAQ_CLICKED = 'trackHelpCardFaqClicked',
  TRACK_TOOKAN_BUTTON_CLICKED = 'trackTookanButtonClicked',
  TRACK_BUTTON_CLICKED_ON_CONFIRMATION_PAGE = 'trackButtonClickedOnConfirmationPage',
  TRACK_CUSTOMER_HAS_SEEN_THE_RESCHEDULE_STEP = 'trackCustomerHasSeenTheRescheduleStep',
  TRACK_CUSTOMER_CLICKED_THE_RESCHEDULE_CTA = 'trackCustomerClickedTheRescheduleCta',
  TRACK_RESCHEDULE_NEW_COLLECTION_DATE_SELECTED = 'trackRescheduleNewCollectionDateSelected',
  TRACK_RESCHEDULE_NEW_COLLECTION_DATE_CONFIRMED = 'trackRescheduleNewCollectionDateConfirmed',

  // Booking Page Interactions
  EDIT_BOOKING_BUTTON_CLICKED = 'editBookingButtonClicked',
  DOWNLOAD_INVOICE_BUTTON_CLICKED = 'downloadInvoiceButtonClicked',
  DOWNLOAD_SERVICE_SHEET_BUTTON_CLICKED = 'downloadServiceSheetButtonClicked',
  VIEW_MOT_CERTIFICATE_BUTTON_CLICKED = 'viewMotCertificateButtonClicked',

  // Enhanced Product Metrics
  PRODUCT_DISPLAY_UNSUPPORTED = 'productDisplayUnsupported',
  PRODUCT_DISPLAY_OFFLINE_QUOTE = 'productDisplayOfflineQuote',

  // Schedule page events
  SCHEDULE_PAGE_LOAD = 'schedulePageLoad',
  SCHEDULE_PAGE_ADDRESS_SELECTED = 'schedulePageAddressSelected',
  SCHEDULE_PAGE_POSTCODE_TYPED = 'schedulePagePostcodeTyped',
  SCHEDULE_PAGE_ADDRESS_TYPED = 'schedulePageAddressTyped',
  SCHEDULE_PAGE_ADDRESS_SUGGESTIONS = 'schedulePageAddressSuggestions',
  SCHEDULE_PAGE_ADDRESS_SUGGESTION_SELECTED = 'schedulePageAddressSuggestionSelected',
  SCHEDULE_PAGE_ADDRESS_FIND_BUTTON_CLICKED = 'schedulePageAddressFindButtonClicked',
  SCHEDULE_PAGE_TRANSPORT_METHOD_SELECTED = 'schedulePageTransportMethodSelected',
  SCHEDULE_PAGE_TIMETABLE_COLLECTION_SLOT_SELECTED = 'schedulePageTimetableCollectionSlotSelected',
  SCHEDULE_PAGE_CUSTOMER_CND_GARAGE_SELECTED = 'schedulePageCustomerCndGarageSelected',
  SCHEDULE_PAGE_CUSTOMER_CND_TIME_SLOT_SELECTED = 'schedulePageCustomerCndTimeSlotSelected',
  SCHEDULE_PAGE_SLOTS_SELECTED = 'schedulePageSlotsSelected',
  SCHEDULE_PAGE_OUT_OF_COVERAGE = 'schedulePageOutOfCoverage',
  SCHEDULE_PAGE_CUSTOMER_CND = 'schedulePageCustomerCnD',

  // Payment page events
  PAYMENT_PAGE_STRIPE_COMPONENT_LOADED = 'paymentPageStripeComponentLoaded',
  PAYMENT_PAGE_STRIPE_ERROR_RESPONSE = 'paymentPageStripeErrorResponse',
  PAYMENT_PAGE_EXISTING_CARD_LOADED = 'paymentPageExistingCardLoaded',
  PAYMENT_PAGE_CHANGE_CARD_CLICKED = 'paymentPageChangeCardClicked',
  PAYMENT_PAGE_CONFIRM_PAYMENT_CLICKED = 'paymentPageConfirmPaymentClicked',
  PAYMENT_PAGE_PAYMENT_METHOD_CLICKED = 'paymentPagePaymentMethodClicked',
  PAYMENT_PAGE_CALL_FOR_KLARNA_CLICKED = 'paymentPageCallForKlarnaClicked',

  // CalloutCard events
  CALLOUT_CARD_EMAIL_CLICKED = 'calloutCardEmailClicked',
  CALLOUT_CARD_PHONE_CLICKED = 'calloutCardPhoneClicked',
  CALLOUT_CARD_LIVE_CHAT_CLICKED = 'calloutCardLiveChatClicked',

  // TeamSupportSection events
  TEAM_SUPPORT_SECTION_EMAIL_CLICKED = 'teamSupportSectionEmailClicked',
  TEAM_SUPPORT_SECTION_PHONE_CLICKED = 'teamSupportSectionPhoneClicked',
  TEAM_SUPPORT_SECTION_LIVE_CHAT_CLICKED = 'teamSupportSectionLiveChatClicked',
  TEAM_SUPPORT_SECTION_CONTACT_FORM_CLICKED = 'teamSupportSectionContactFormClicked',

  // NeedHelp Modal
  NEED_HELP_BUTTON_CLICKED = 'needHelpButtonClicked',
  NEED_HELP_MODAL_FAQ_CLICKED = 'needHelpModalFAQClicked',

  // Quotes Page
  ADD_TO_BASKET = 'addToBasket',
  ADD_TO_BASKET_WITH_PRICE_DATA = 'addToBasketWithPriceData',
  QUOTES_PAGE_DISPLAYED = 'quotesPageDisplayed',
  MOT_PAGE_DISPLAYED = 'motPageDisplayed',
  SERVICES_PAGE_DISPLAYED = 'servicesPageDisplayed',
  UPSELL_CLICKED_ON_WHATS_INCLUDED = 'upsellClickedOnWhatsIncluded',
  COMPARISON_TABLE_CLICKED_ON_FIXTER_GUARANTEES = 'comparisonTableClickedOnFixterGuarantees',
  COMPARISON_TABLE_CLICKED_ON_FULL_DESCRIPTION = 'comparisonTableClickedOnFullDescription',
  COMPARISON_TABLE_CLICKED_ON_CHECK_LIST = 'comparisonTableClickedOnCheckList',
  COMPARISON_TABLE_CLICKED_ON_ENGINE_OIL = 'comparisonTableClickedOnEngineOil',
  SERVICING_TAB_CLICKED_ON_FIXTER_GUARANTEES = 'servicingTabClickedOnFixterGuarantees',
  SERVICING_TAB_CLICKED_ON_FULL_DESCRIPTION = 'servicingTabClickedOnFullDescription',
  SERVICING_TAB_CLICKED_ON_CHECK_LIST = 'servicingTabClickedOnCheckList',
  SERVICING_TAB_CLICKED_ON_ENGINE_OIL = 'servicingTabClickedOnEngineOil',
  NEED_OTHER_SERVICES_CLICKED = 'needOtherServicesClicked',
  PRODUCT_TAB_CLICKED = 'productTabClicked',
}

export enum AnalyticsServiceFieldType {
  STRING = 'string',
  JSON = 'json',
}
