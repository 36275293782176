'use client';

/* eslint-disable no-console */
import { useState, useEffect, useRef } from 'react';
import type { Locale } from '@fixter/i18n';
import type {
  ActivateExperimentCustomEvent,
  KameleoonExperiment,
  LegalConsentCustomEvent,
} from '$util/abTest/types';
import { isBrowser, isLocalhost } from '../index';
import { getABTestGoal } from './goals';

export const originalExperimentBranch = 'Reference';
export const eventKameleoonExperimentActivated = 'Kameleoon::ExperimentActivated';
export const eventKameleoonLegalConsentUpdated = 'Kameleoon::LegalConsentUpdated';

declare global {
  interface Window {
    Kameleoon?: KameleoonInstance;
  }
}

export interface KameleoonInstance {
  readonly API: {
    readonly Experiments: {
      /**
       *  @link https://developers.kameleoon.com/apis/activation-api-js/api-reference/#getactivatedinvisit
       */
      readonly getByName: (name: string) => KameleoonExperiment;
      /**
       *  @link https://developers.kameleoon.com/apis/activation-api-js/api-reference/#getactivatedinvisit
       */
      readonly getActivatedInVisit: () => KameleoonExperiment[];
    };
    readonly Visitor: {
      readonly personalizationLegalConsent: boolean;
    };
    readonly Core: {
      /**
       * @link https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Security-Policy
       */
      readonly load: () => void;
    };
    readonly Goals: {
      /**
       * @link https://developers.kameleoon.com/apis/activation-api-js/api-reference/#processconversion
       */
      readonly processConversion: (goalId: number) => void;
    };
    readonly Events: {
      /**
       * @link https://developers.kameleoon.com/apis/activation-api-js/api-reference#trigger
       */
      readonly trigger: (eventName: string) => void;
    };
    readonly Data: {
      /**
       * @link https://developers.kameleoon.com/apis/activation-api-js/api-reference/#setcustomdata
       */
      readonly setCustomData: (fieldName: string, fieldValue: string | boolean | number) => void;
    };
  };
}

/**
 * Determines if the legal consent is active on the page
 * Used in getActiveExperiments because when the consent is changed to false
 * Kameleoon does not change the state of experiments in the current visit
 * which leads to inaccurately setting experiments in session data
 */
export const isPersonalizationLegalConsentActive = (): boolean => {
  console.debug(`Attempting to check if personalization legal consent is active`);
  if (!isBrowser) return false;
  if (isLocalhost()) return true;

  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn('Kameleoon not loaded yet');
    return false;
  }
  return !!Kameleoon.API.Visitor.personalizationLegalConsent;
};

export const isBeforeLegalConsentChosen = (): boolean => {
  console.debug(`Attempting to check if legal consent was not chosen yet`);
  if (!isBrowser) return false;
  if (isLocalhost()) return false;

  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn('Kameleoon not loaded yet');
    return true;
  }
  return Kameleoon.API.Visitor.personalizationLegalConsent === null;
};

/**
 * Determine if an experiment is active with a variation on the server.
 * Currently, the only way to check if an experiment is active on the server is to pass the list of experiments to the
 * server and decide.
 *
 * @param serializedActiveExperimentsList 'TEST_DEV3_OANA:B,TEST_GRAPHICS2:B,NEW_QUOTES_PAGE:B'
 * @param experimentName 'NEW_QUOTES_PAGE'
 * @param experimentVariation 'B'
 */
export const isActiveExperimentServer = (
  serializedActiveExperimentsList: string,
  experimentName: string,
  experimentVariation: string
): boolean => {
  try {
    const experiment = `${experimentName}:${experimentVariation}`;
    const parsedExperiments = serializedActiveExperimentsList.split(',');
    if (parsedExperiments.includes(experiment)) return true;
    return false;
  } catch (e) {
    return false;
  }
};

/**
 * Determine if an experiment is active with a variation on the client.
 * If `variationName` is missing, it is considered the `Reference` version.
 */
export const isActiveExperimentClient = (
  experimentName: string,
  variationName = originalExperimentBranch
): boolean => {
  console.debug(`Attempting to check if experiment ${experimentName}:${variationName} is active`);
  if (!isBrowser) return false;

  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn('Kameleoon not loaded yet');
    return false;
  }
  const experiment: KameleoonExperiment = Kameleoon.API.Experiments.getByName(experimentName);

  if (!experiment || !experiment.active) {
    console.debug(`Experiment ${experimentName}:${variationName} does not exist or is inactive`);
    return false;
  }

  if (experiment.associatedVariation.name === variationName) {
    console.info(`Experiment ${experimentName}:${variationName} is active`);
    return true;
  }

  console.debug(`Experiment ${experimentName}:${variationName} is inactive`);
  return false;
};

export const getActiveExperimentsArray = (): string[] => {
  console.debug('Attempting to get active experiments');
  if (!isBrowser) return [];
  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn('Kameleoon not loaded yet');
    return [];
  }
  if (!isPersonalizationLegalConsentActive()) return [];
  const experiments: KameleoonExperiment[] = Kameleoon.API.Experiments.getActivatedInVisit();
  const activeExperiments = experiments.map(
    ({ name: experimentName, associatedVariation: { name: variationName } }) =>
      `${experimentName}:${variationName}`
  );
  return activeExperiments;
};

export const getActiveExperiments = (): string => {
  const activeExperiments = getActiveExperimentsArray().join(',');
  console.info('Active experiments: ', activeExperiments);
  return activeExperiments;
};

export const useActiveExperiment = (
  experimentName: string,
  variationName = originalExperimentBranch
): boolean => {
  const [renderCSR, setRenderCSR] = useState(false);
  const isOriginalVariation = useRef(false);
  useEffect(() => {
    /**
     * If an experiment is activated later than the first render, then we use
     * the event Kameleoon sends when the experiment gets activated to render the experiment
     */
    const onExperimentActivated = (event: ActivateExperimentCustomEvent) => {
      const {
        active,
        name,
        associatedVariation: { name: eventVariationName },
      } = event.detail.experiment;
      console.debug(`ExperimentActivated event triggered ${name}:${eventVariationName}`);
      if (active && name === experimentName && variationName === eventVariationName) setRenderCSR(true);
      isOriginalVariation.current = eventVariationName === originalExperimentBranch;
    };
    if (!renderCSR && !isOriginalVariation.current) {
      if (isActiveExperimentClient(experimentName, variationName)) setRenderCSR(true);
      else window.addEventListener(eventKameleoonExperimentActivated, onExperimentActivated);
    }
    return () => window.removeEventListener(eventKameleoonExperimentActivated, onExperimentActivated);
  }, [experimentName, renderCSR, variationName]);

  useEffect(() => {
    /**
     * The Kameleoon behaviour we use when consent is unknown is to show all experiments
     * After the visitor selects its choice Kameleoon does not send an event to disable the experiments
     * This is why we are listening for LegalConsentUpdated event from Kameleoon to check again the state of the experiment
     */
    const onConsentUpdated = (event: LegalConsentCustomEvent) => {
      const { AB_TESTING, PERSONALIZATION } = event.detail.legalConsentUpdate;
      console.debug(`LegalConsentUpdated event triggered: ${AB_TESTING}`);
      const doRender =
        AB_TESTING && PERSONALIZATION && isActiveExperimentClient(experimentName, variationName);
      setRenderCSR(doRender);
      /**
       * when the page loads without consent all experiments are in active state
       * after the consent changes they will remain stuck in this state
       * unless Kameleoon reload is triggered
       */
      window.Kameleoon?.API.Core.load();
      console.debug('Kameleoon reloaded');
    };
    if (!isOriginalVariation.current && isBeforeLegalConsentChosen())
      window.addEventListener(eventKameleoonLegalConsentUpdated, onConsentUpdated, { once: true });
  }, [experimentName, renderCSR, variationName]);
  return renderCSR;
};

export const triggerGoal = (goal: string, locale: Locale): void => {
  console.debug(`Attempting to trigger ${goal} goal for ${locale}`);
  if (!isBrowser) return;
  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn('Kameleoon not loaded yet - trigger goal');
    return;
  }
  const goalId = getABTestGoal(goal, locale);
  if (goalId) {
    Kameleoon.API.Goals.processConversion(goalId);
    console.debug(`Triggering ${goal} goal for ${locale}`);
  } else console.warn(`The goal ${goal} does not exist!`);
};

export const triggerEvent = (eventName: string): void => {
  console.debug(`Attempting to trigger ${eventName}`);
  if (!isBrowser) return;
  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn(`Kameleoon not loaded yet - trigger custom event ${eventName}`);
    return;
  }

  Kameleoon.API.Events.trigger(eventName);
  console.debug(`Triggering custom event ${eventName}`);
};

export const setCustomData = (fieldName: string, fieldValue: string | number | boolean): void => {
  console.debug(`Attempting to set custom data: ${fieldName} = ${fieldValue}`);
  if (!isBrowser) return;
  const { Kameleoon } = window;

  if (!Kameleoon) {
    console.warn(`Kameleoon not loaded yet - set custom data ${fieldName} = ${fieldValue}`);
    return;
  }

  Kameleoon.API.Data.setCustomData(fieldName, fieldValue);

  console.debug(`Setting custom data ${fieldName} = ${fieldValue}`);
};
